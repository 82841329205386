













import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'

@Component({
  components: {
    ListView,
  },
})
export default class List extends Vue {
  headers = []
  routes = {}
  rowActions = []
  topActions = []

  mounted() {
    this.headers = [
      {
        text: 'ID',
        value: 'id',
      },
      {
        text: 'Name',
        value: 'name',
        filter: { focus: true },
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]

    this.rowActions = [
      {
        id: 'edit',
        route: (item) => {
          return {
            name: 'Settings/Booking/Schedules/Edit',
            params: { calendarId: this.$route.params.calendarId, id: item.id },
          }
        },
      },
      {
        id: 'delete',
      },
    ]

    this.topActions = [
      {
        id: 'new',
        route: () => {
          return {
            name: 'Settings/Booking/Schedules/Edit',
            params: { calendarId: this.$route.params.calendarId, id: '0', isNew: true },
          }
        },
      },
    ]
  }

  get url() {
    return '/v4/site/calendars/' + this.$route.params.calendarId + '/schedules'
  }
}
